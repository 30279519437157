<template>
  <div
    class="service-area ptb--20 bg_color--5"
    v-bind:style="{ backgroundImage: 'url(' + src + ')' }"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-left section-title mb--10  pt--80">
            <h2>
              <vue-typer
                :text="title"
                :repeat="Infinity"
                :shuffle="false"
                initial-action="typing"
                :pre-type-delay="70"
                :type-delay="70"
                :pre-erase-delay="2000"
                :erase-delay="250"
                erase-style="select-all"
                :erase-on-complete="false"
                caret-animation="blink"
              ></vue-typer>
            </h2>

            <h2>
              <vue-typer
                :text="desc"
                :repeat="Infinity"
                :shuffle="false"
                initial-action="typing"
                :pre-type-delay="70"
                :type-delay="70"
                :pre-erase-delay="2000"
                :erase-delay="250"
                erase-style="select-all"
                :erase-on-complete="false"
                caret-animation="blink"
              ></vue-typer>
            </h2>

            <a
              target="_blank"
              href="https://assistant.google.com/services/a/uid/0000002fc831fd88?hl=fr"
              ><img
                alt="Works with Google"
                v-bind:src="logoG"
                style="max-width:200px"
            /></a>

            <a
              target="_blank"
              href="https://alexa-skills.amazon.fr/apis/custom/skills/amzn1.ask.skill.143b926e-dc46-4c97-a979-026b6f3004ba/launch"
              ><img
                alt="just ask"
                v-bind:src="logoA"
                style=" margin-left:20px; max-width:150px"
            /></a>
          </div>
        </v-col>
        <v-col cols="12" class="align-content-center text-center "
          >* Cette invocation nécessite la création d'une routine sur votre
          assistant .<br />
          <a href="https://youtu.be/k9k7pWj52B0" target="_blank"
            >voir le mode d'emploi sur youtube</a
          >
        </v-col>
      </v-row>
      <!-- End .row -->
    </v-container>
  </div>
</template>

<script>
import { VueTyper } from "vue-typer";
export default {
  components: {
    VueTyper
  },
  name: "banGoogle",
  data() {
    return {
      src: require("../../assets/images/bg/banOP.jpeg"),
      desc: [` à l'aide!*`, ` au secours!*`],
      title: [` OK GOOGLE `, ` ALEXA`],
      logoG: require("../../assets/images/logo/logo_google.png"),
      logoA: require("../../assets/images/logo/logo_alexa.png")
    };
  }
};
</script>
<style scoped>
div {
  background-size: cover;
  background-repeat: no-repeat;
}
a {
  color: var(--color-gray) !important;
}
a:link {
  text-decoration: underline;
}
</style>
