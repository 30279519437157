/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:ccd04e75-7ade-4d4a-bdaf-3b86cb9ba087",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_6aPvVBOnJ",
    "aws_user_pools_web_client_id": "15m89qie8nmfdptmhomhuvglam",
    "oauth": {
        "domain": "teleassistanceseniorb4a59e62-b4a59e62-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "amisdeconfiance://,https://dev.amisdeconfiance.com/,https://dev.amisdeconfiance.com/user/alexalink,https://www.amisdeconfiance.com/user/alexalink,https://layla.amazon.com/api/skill/link/MMK30RJLNGJ09,https://pitangui.amazon.com/api/skill/link/MMK30RJLNGJ09,https://alexa.amazon.co.jp.amazon.com/api/skill/link/MMK30RJLNGJ09,https://oauth-redirect.googleusercontent.com/r/teleassistancesenior-snkn,https://oauth-redirect.googleusercontent.com/r/mes-amis-de-confiance,https://www.amisdeconfiance.com/",
        "redirectSignOut": "amisdeconfiance://,https://dev.amisdeconfiance.com/,https://www.amisdeconfiance.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "AMAZON",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://4itugx5skvb3ti5mhu4vey2ffi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "teleassistanceseniorstripeapi",
            "endpoint": "https://iddmum8vrd.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "teleassistancesenior0cfffb9acontactapi",
            "endpoint": "https://0qhuf7702d.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "apicc7ddc29friend",
            "endpoint": "https://nuzgb89jef.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "api75e3f406audio",
            "endpoint": "https://5bue6z59b7.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "teleassistancesenioruserapi321",
            "endpoint": "https://iw6uo91p8j.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "teleassistanceseniorsbilling321",
            "endpoint": "https://l9iaxbq0g5.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
