<template>
  <div>
    <!-- Start Slider Area -->
    <SliderFour />
    <!-- End Slider Area -->

    <div class="rn-service-details ptb--60 bg_color--1" id="home">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content -->
                <v-row class="sercice-details-content align-center row--35">
                  <v-col lg="6" md="6" cols="12" order-md="2">
                    <div class="thumb position-relative">
                      <div class="video-responsive">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/cDaoyGlTuWA"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title">
                        <h1>
                          <span class="subtitle">Mes amis de confiance</span>
                        </h1>
                        <h2 class="heading-title">
                          Les amis ou la famille sont à portée de voix
                        </h2>
                        <p class="description">
                          Mes amis de confiance est
                          <span style="font-weight: 800"
                            >un service d'alerting
                          </span>
                          disponible sur les assistants vocaux Amazon Alexa et
                          Google Assistant. <br />
                          <span style="font-weight: 800">
                            Ce service permet d’envoyer des notifications pour
                            alerter vos proches quand vous avez besoin
                            d’assistance simplement en utilisant votre voix.
                          </span>
                        </p>
                        <p class="description">
                          Lorsque vous demandez de l’assistance, nous passons un
                          appel vocal, envoyons un SMS et un e-mail à chacun des
                          contacts que vous avez pré-renseigné sur notre
                          plateforme lors de votre inscription.
                        </p>
                        <span id="banGoogle"></span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <BanPub></BanPub>

    <!-- Start team Area -->
    <div class="rn-team-area ptb--60 bg_color--1" id="service">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="section-title text-center mb--25 mb_sm--0">
              <span class="subtitle">Mon réseau d’alerte personnel</span>
              <h2 class="heading-title">Comment ça marche?</h2>
              <p>
                Aucun équipement n'est requis, tous les appels vocaux, SMS et
                e-mails à vos contacts proviennent de notre service.
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceFour />
      </v-container>
      <div id="op"></div>
    </div>
    <!-- End team Area -->

    <BanPub></BanPub>

    <!-- Start About Area -->
    <div class="about-area rm-about-style-2 ptb--120 bg_color--5" id="avantage">
      <v-container>
        <AboutThree />
      </v-container>
    </div>
    <!-- End About Area -->

    <!-- Start Pricing Plan Area -->
    <div class="rn-pricing-table-area ptb--120" id="tarif">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div
              class="section-title service-style--3 text-center mb--25 mb_sm--0"
            >
              <h2 class="heading-title">Tarif</h2>
              <p>un service à prix mini</p>
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
        <PricingPlanOp v-if="!isAuthenticated"> </PricingPlanOp>
        <PricingPlan2 v-if="isAuthenticated"> </PricingPlan2>
      </v-container>
    </div>
    <!-- End Pricing Plan Area -->

    <!-- Start countreup Area -->
    <div
      class="
        rn-countreupup-area
        pt--60
        pb--120
        bg_image bg_image--17
        bg-position-fixed
        theme-text-white
      "
      data-black-overlay="3"
    >
      <div class="container">
        <CounterOne />
      </div>
    </div>
    <!-- Start countreup Area -->

    <!-- FAQ -->
    <div class="pv-feaq-area ptb--120" id="FAQ">
      <v-container>
        <v-row>
          <v-col lg="8" offset-lg="2">
            <div class="text-left section-title pb--30">
              <span class="subtitle theme-gradient">
                Des questions ? Nous avons les réponses....</span
              >
              <h2 class="heading-title">Questions fréquentes (FAQ)</h2>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="8" offset-lg="2">
            <div class="faq-area">
              <v-expansion-panels
                accordion
                flat
                v-model="panel"
                mandatory
                class="about-expension-panels home-page-panels"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Pourquoi utiliser un assistant vocal pour un service
                    d'alerting?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Les assistants vocaux permettent une interaction sans être
                      proximité . Tout devient « à portée de voix ». Vous êtes
                      dans une situation délicate, plus besoin de rechercher
                      votre téléphone ou un autre dispositif pour demander à vos
                      proches de l'assistance. Il suffit juste de demander à
                      votre assistant " demande à mes amis de confiance de
                      l'assistance" et nous nous chargeons de prévenir vos
                      proches que vous avez besoin d'aide.
                    </p></v-expansion-panel-content
                  >
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Votre service "Mes amis de confiance" est il un service de
                    télé-assistance ?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Non ce service, n’est pas un service de télé-assistance.
                      Mes amis de confiance est une solution technique qui
                      permet de notifier par sms , appel vocal et mail chacun
                      des contacts que vous avez pré-renseigné sur notre
                      plateforme lors de votre inscription. Ce service ne
                      contacte pas les services de secours. C’est à vos proches
                      notifiés par votre demande d’assistance d’évaluer la
                      situation et de prévenir les secours s’il y a lieu.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Comment marche le service d'alerting sur les assistants?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Le service d’alerting permet d’envoyer des alertes à vos
                      proches directement en demandant de l’assistance à votre
                      assistant vocal Google ou Amazon Alexa. Lorsque vous
                      demandez de l’assistance, nous passons un appel vocal,
                      envoyons un SMS et un e-mail à chacun des contacts que
                      vous avez pré-renseigner sur notre plateforme lors de
                      votre inscription.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- End v-expension-panel -->
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Comment configurer mon application vocale "mes amis de
                    confiance" sur Alexa
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Pour utiliser le service sur Alexa vous devez:
                    </p>

                    <ol>
                      <li>
                        Activer la skill sur Alexa sur le portail Alexa (lien
                        <a
                          href="https://alexa-skills.amazon.fr/apis/custom/skills/amzn1.ask.skill.143b926e-dc46-4c97-a979-026b6f3004ba/launch"
                          >ici </a
                        >)
                      </li>
                      <li>
                        Vous connecter avec votre compte Alexa lors de votre
                        inscription (le même compte qui a servi à paramétrer
                        votre assistant )
                      </li>
                      <li>
                        Saisir les coordonnées ( nom , prénom téléphone fixe ou
                        portable , e-mail) une liste d’amis , proches , voisins
                        de confiance susceptibles de répondre à une demande
                        d’assistance . Nous notifierons par mail ou sms tous vos
                        contacts qui font partie de votre réseau de confiance.
                      </li>
                    </ol>
                    Voilà c'est terminé À partir de maintenant vous pouvez
                    utiliser le service quand vous voulez il suffit de dire:
                    <em>
                      <span class="subtitle theme-gradient"
                        >"Alexa, demande à mes amis de confiance de
                        l'assistance"
                      </span></em
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Comment personnaliser l’invocation d'une demande
                    d’assistance sur alexa "Alexa je suis tombé"
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Si vous voulez personnaliser l’invocation d'une demande
                      d’assistance sur alexa en demandant :
                      <br />"ALEXA je suis tombé <br />
                      , <br />
                      ALEXA au secours <br />
                      ou une autre phrase personaliser , vous devez creer une
                      "routine" Alexa.
                    </p>
                    <p>
                      Pour créer une routine, lancer votre application alexa sur
                      votre smartphone puis suivez les instructions suivantes
                    </p>
                    <ul>
                      <li>
                        Dans le menu sélectionnez <em>plus</em> puis cliquez sur
                        l’icône <em>Routines</em>
                      </li>
                      <li>
                        Ajouter une nouvelle routine en cliquant sur l’icône
                        <em>+</em>
                      </li>
                      <li>
                        <ul>
                          <li>Saisissez un nom de routine</li>
                          <li>
                            cliquez sur <em>«lorsque cela ce produit» </em> puis
                            sélectionner <em> voix</em> et saisissez vos
                            messages personnalisés, par exemple «je suis tombé»
                            , vous pouvez saisir plusieurs sur cet écran.
                            Cliquez ensuite sur le bouton suivant
                          </li>
                          <li>
                            Cliquez sur le lien
                            <em> ajouter une action </em> puis sélectionner le
                            lien <em>skill</em>, puis <em>vos skills</em> et
                            enfin cliquez sur l’icône
                            <em>mes amis de confiance.</em>
                          </li>
                        </ul>
                      </li>
                    </ul>

                    Voilà c'est terminé désormais vous pourrez accéder au
                    service mes amis de confiance directement en prononçant la
                    phrase
                    <em>
                      <span class="subtitle theme-gradient"
                        >"Alexa, je suis tombé"
                      </span></em
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Comment configurer mon application vocale "mes amis de
                    confiance" sur Google assistant
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Pour utiliser le service sur Google assistants vous devez:
                    </p>

                    <ol>
                      <li>Souscrire à un pack</li>
                      <li>
                        Vous connecter avec votre compte Google lors de votre
                        inscription (le même compte qui a servi à paramétrer
                        votre assistant )
                      </li>
                      <li>
                        Saisir les coordonnées ( nom , prénom téléphone fixe ou
                        portable , e-mail) une liste d’amis , proches , voisins
                        de confiance susceptibles de répondre à une demande
                        d’assistance . Nous notifierons par mail ou sms tous vos
                        contacts qui font partie de votre réseau de confiance.
                      </li>
                      <li>
                        Activer l’action sur Google assistant en demandant "Ok
                        Google, parler à mes amis de confiance" <br />
                        Lors de cette première connexion, l’assistant vous
                        demandera de vous connecter avec votre compte Google
                        puis vous confirmera que la procédure d’initialisation
                        est terminée en vous donnant la liste des contacts de
                        confiance .
                      </li>
                    </ol>
                    Voilà c'est terminé À partir de maintenant vous pouvez
                    uliliser le service quand vous voulez il suffit de dire:
                    <em>
                      <span class="subtitle theme-gradient"
                        >"Ok, Google demande à mes amis de confiance de
                        l'assistance"
                      </span></em
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- End v-expension-panel -->

                <!-- End v-expension-panel -->
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Comment ajouter des contacts a mon service ?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Pour ajouter des contacts rien de plus simple. RDV dans
                      votre espace personnel sur le site en cliquant sur le
                      bouton en haut à droite de votre écran. Vous pouvez
                      ajouter / modifier vos contacts.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- End v-expension-panel -->
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Puis-je être rappelé par mon contact directement sur mon
                    assistant apres ma demande d'assistance?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    ><p>
                      Vous pouvez être rappelé sur votre assistant directement
                      après votre demande d’assistance uniquement si votre
                      contact possède un assistant vocal de la même marque que
                      vous. Par exemple si vous faites une demande d’assistance
                      depuis un Echo Alexa, votre contact pourra vous joindre
                      directement sur votre enceinte Alexa si ce dernier dispose
                      lui aussi d’un dispositif Alexa.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- End v-expension-panel -->
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Comment tester le système d'alerting ?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Vous pouvez testez le bon fonctionnement du systeme
                      d’alerting en demandant a votre assistant:
                      <br /><em>
                        <span class="subtitle theme-gradient"
                          >"Hey Google, parler à mes amis de confiance"
                        </span></em
                      >
                      <br />
                      <em>
                        <span class="subtitle theme-gradient"
                          >"Alexa, lance mes amis de confiance"
                        </span></em
                      >

                      <br />L’assistant vous donnera le statut du système et le
                      nombre de contacts que vous avez pré-renseignés sur notre
                      plateforme
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- End v-expension-panel -->
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Comment prévenir mes contacts que tout va bien ?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Vous pouvez envoyer une notification à vos contacts pour
                      les prévenir que tout va bien. Pour cela il suffit de dire
                      <br /><em>
                        <span class="subtitle theme-gradient"
                          >"Hey Google, demande à mes amis de confiance que tout
                          va bien."
                        </span></em
                      >
                      <br />
                      <em>
                        <span class="subtitle theme-gradient"
                          >"Alexa, demande à mes amis de confiance tout va
                          bien."
                        </span></em
                      >
                    </p>
                    <p>
                      vous pouvez à tout moment interagir avec votre application
                      vocale "mes amis de confiance" sur votre assistant et vous
                      laisser guider :
                      <br /><em>
                        <span class="subtitle theme-gradient"
                          >Hey Google parler à mes amis de confiance
                        </span></em
                      >
                      <br />
                      <em>
                        <span class="subtitle theme-gradient"
                          >Alexa lance mes amis de confiance
                        </span></em
                      >
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- End v-expension-panel -->

                <!-- End v-expension-panel -->
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Combien de fois puis-je utiliser le service avec l'offre
                    Découverte ?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Avec le
                      <em>
                        <span class="subtitle theme-gradient"
                          >Pack Découverte</span
                        >
                      </em>
                      vous pouvez ajouter un seul contact à votre liste d’amis
                      de confiance et vous pouvez utiliser le service de
                      notification une seule fois par mois. Pour profiter
                      pleinement du service passez au pack premium. Pour
                      seulement 2.99 &euro;** par mois vous pourrez ajouter
                      jusqu’à 5 contacts et envoyer jusqu’à 50 demandes
                      d’assistance par mois !
                      <br />
                      **
                      <span style="font-size: x-small">
                        2.99 &euro; par mois avec un engagement d'un an ou 5
                        &euro; par mois sans engagement.
                      </span>
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- End v-expension-panel -->
                <!-- End v-expension-panel -->
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    Combien de fois puis-je utiliser le service avec l'offre
                    Assistance ?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Avec le
                      <em>
                        <span class="subtitle theme-gradient"
                          >Pack Assistance</span
                        >
                      </em>
                      profitez pleinement du service mes amis de confiance. Pour
                      seulement 2.99 &euro;** par mois vous pourrez ajouter
                      jusqu’à 5 contacts et envoyer jusqu’à 50 demandes
                      d’assistance* par mois !
                      <br />
                      *
                      <span style="font-size: x-small">
                        une demande d'assistance est une notification à tous vos
                        contacts pour demander de l'assistance ou pour les
                        rassurer.
                      </span>
                      <br />
                      **
                      <span style="font-size: x-small">
                        2.99 &euro; par mois avec un engagement d'un an ou 5
                        &euro; par mois sans engagement.
                      </span>
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- End v-expension-panel -->
              </v-expansion-panels>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- END FAQ -->
    <h2></h2>

    <!-- Start Contact Area -->
    <div class="rn-contact-us ptb--120 bg_color--5" id="contact">
      <div class="contact-form--1">
        <v-container>
          <Contact>
            <img
              slot="contact-img"
              class="w-100"
              src="../assets/images/about/carte.jpg"
              alt="contact images"
            />
          </Contact>
        </v-container>
      </div>
    </div>
    <!-- End Contact Area -->

    <!-- Start Footer Area -->
    <Footer />
    <!-- End Footer Area -->
  </div>
</template>
<script>
import SliderFour from "../components/slider/SliderFour";
import ServiceFour from "../components/service/ServiceFour";
import AboutThree from "../components/about/AboutThree";
import CounterOne from "../components/counter/CounterOne";
import PricingPlanOp from "../components/pricing-plan/PricingPlanOp";
import PricingPlan2 from "../components/pricing-plan/PricingPlanUser";
import Contact from "../components/contact/Contact";
import BanPub from "../components/banniere/BanPub";
import { mapGetters } from "vuex";

export default {
  components: {
    BanPub,
    SliderFour,
    ServiceFour,
    AboutThree,
    CounterOne,
    PricingPlanOp,
    PricingPlan2,

    Contact
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  data() {
    return {
      items: [
        {
          thumb: require("../assets/images/about/about-10.png"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8"
        }
      ],
      panel: 1,
      index: null
    };
  }
};
</script>

<style lang="scss">
.rn-countreupup-area {
  &.pt--60 {
    @media only screen and (max-width: 991px) {
      padding-top: 20px;
    }
    @media only screen and (max-width: 767px) {
      padding-top: 8px;
    }
  }
}

ol li {
  margin-left: 20px;
}

.v-application p,
body p {
  font-size: 16px;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>
