import Vue from "vue";
import VueRouter from "vue-router";
import Missing from "../views2/404.vue";
import home from "../views2/home.vue";
import ads from "../views2/ads.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    //redirect: "/dashboard",
    component: () => import("@/views2/Layout"),
    children: [
      {
        path: "/",
        component: home,
        meta: {
          title:
            "Mes amis de confiance, le service d'alerting disponible sur le assistants vocaux Amazon Alexa et Google Assistant."
        }
      },
      {
        path: "/ads",
        component: ads,
        meta: {
          title:
            "Mes amis de confiance, le service d'alerting disponible sur le assistants vocaux Amazon Alexa et Google Assistant."
        }
      },
      {
        path: "/CGU",
        name: "CGU",
        meta: {
          title: "Condition Générale  d'Utilisation"
        },
        component: () => import("../views2/cgu.vue")
      },
      {
        path: "/unsubscribe",
        name: "Désinscription",
        meta: {
          title: "unsubscribe"
        },
        component: () => import("../views2/unsubscribe.vue")
      },
      {
        path: "/vote",
        name: "Vote",
        meta: {
          title: "Trophées Silver Eco"
        },
        component: () => import("../views2/vote.vue")
      },
      {
        path: "/alert-answer",
        name: "AlertAnswer",
        meta: {
          title: "Alert"
        },
        component: () => import("../views2/alert-answer.vue")
      },
      {
        path: "/cookies",
        name: "cookies",
        meta: {
          title: "Données personnelles et cookies"
        },
        component: () => import("../views2/cookies.vue")
      },
      {
        path: "/alexa",
        name: "alexa",
        meta: {
          title: "Mes amis de confiance sur Alexa"
        },
        component: () => import("../views2/blog/Alexa.vue")
      },
      {
        path: "/alexav2",
        name: "alexav2",
        meta: {
          title: "Mes amis de confiance sur Alexa"
        },
        component: () => import("../views2/blog/AlexaSetup.vue")
      },
      {
        path: "/google",
        name: "google",
        meta: {
          title: "Mes amis de confiance sur Google Assistant"
        },
        component: () => import("../views2/blog/Google.vue")
      },
      {
        path: "/about",
        name: "about",
        meta: {
          title: "Qui sommes nous"
        },
        component: () => import("../views2/About.vue")
      },
      {
        path: "/user/subscription",
        name: "Plan",
        meta: {
          title: "Plan",
          authorize: true
        },
        component: () => import("../views2/user/Subscription.vue")
      },
      {
        path: "/user/account",
        name: "Account",
        meta: {
          title: "Account",
          authorize: true
        },
        component: () => import("../views2/user/Account.vue")
      },
      {
        path: "/user/login",
        name: "Login",
        meta: {
          title: "Login"
        },
        component: () => import("../views2/user/Login.vue")
      },
      {
        path: "/user/dashboard",
        name: "Dashboard",
        meta: {
          title: "Dashboard",
          authorize: true
        },
        component: () => import("../views2/user/Dashboard.vue")
      },
      {
        path: "/user/stepper",
        name: "Stepper",
        meta: {
          title: "Stepper",
          authorize: true
        },
        component: () => import("../views2/user/Stepper.vue")
      },
      {
        path: "/user/stepper-oauth",
        name: "StepperoAuth",
        meta: {
          title: "Stepper",
          authorize: true
        },
        component: () => import("../views2/user/StepperoAuth.vue")
      },
      {
        path: "/user/alexalink",
        name: "apptoapp",
        meta: {
          title: "Activation Skill Alexa",
          authorize: true
        },
        component: () => import("../views2/activate/AlexaLink.vue")
      },
      {
        path: "/user/activate",
        name: "activateapp",
        meta: {
          title: "Activation de l'application",
          authorize: true
        },
        component: () => import("../views2/activate/ActivateApp")
      }
    ]
  },

  {
    path: "/checkout-success",
    name: "checkout-success",
    meta: {
      title: "Validation",
      authorize: true
    },
    component: () => import("@/views2/Success.vue")
  },
  {
    path: "*",
    component: Missing,
    meta: {
      title: "404"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      };
    } else if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const { authorize } = to.meta;
  if (authorize) {
    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
      .then(() => {
        next();
      })
      .catch(() => {
        return next({ name: "Login", params: { returnUrl: to.path } });
      });
    return;
  }
  next();
});

export default router;
