<template>
  <v-row class="service-main-wrapper row-flex ">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="4"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div
        class="service service__style--2 text-center bg-gray rn-pricing"
        style="margin-top:2em;"
      >
        <div class="service">
          <div class="icon">
            <img :src="service.icon" width="100px" height="100px" />
          </div>
          <div class="content">
            <h4 class="heading-title min-height70">{{ service.title }}</h4>
            <hr />
            <p>
              <span v-html="service.desc"></span>
            </p>
            <p>
              {{ service.google }}
            </p>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      serviceContent: [
        {
          icon: require("../../assets/images/icons/commande-vocale.png"),
          title: "Vous avez besoin d'assistance",
          desc: ` Demander à votre assistant  <p> <br> <em>OK GOOGLE demande à mes amis de confiance de l'assistance </br> <br> <em>Alexa demande à mes amis de confiance de l'assistance </em></br> `
        },
        {
          icon: require("../../assets/images/icons/alert.png"),
          title: "Alerting",
          desc: ` Nous passons un appel vocal, envoyons un SMS et un e-mail à chacun de vos contacts <p> <em> "Monsieur P" vous envoie une demande d'assistance depuis son Assistant </em> </p> `
        },
        {
          icon: require("../../assets/images/icons/personal-assistant.png"),
          title: "Assistance",
          desc: ` Votre contact peut vous joindre directement depuis son assistant (s'il est équipé) ou prévenir les secours si cela est pertinent`
        }
      ]
    };
  }
};
</script>

<style>
.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.service {
  height: 100%;
  padding: 1em;
}
.icon {
  margin-bottom: 0.5em;
}

.min-height70 {
  min-height: 70px;
}
</style>
