<template>
  <v-row justify="center">
    <v-col
      lg="4"
      md="4"
      sm="4"
      cols="12"
      v-for="pricing in pricingContent"
      :key="pricing.id"
    >
      <div class="rn-pricing" :class="{ active: pricing.active }">
        <div class="pricing-table-inner">
          <div class="pricing-header">
            <h4 class="heading-title">{{ pricing.title }}</h4>
            <div class="pricing">
              <span class="price">{{ pricing.price }}€</span>
              <span class="subtitle">{{ pricing.subtitle }}</span>
            </div>
          </div>

          <div class="pricing-body">
            <ul class="list-style--1">
              <li v-for="(singleList, i) in pricing.listItem" :key="i">
                <v-icon light dense v-bind:color="pricing.color"
                  >mdi-check</v-icon
                >{{ singleList.list }}
              </li>
            </ul>
          </div>

          <div class="pricing-footer">
            <v-btn
              type="button"
              class="btn-custom"
              variant="outline-primary"
              :ref="'button' + pricing.slug"
              v-if="
                pricing.slug == 'FREE' &&
                  (subscription == pricing.slug || subscription == undefined)
              "
              @click="tryAppPerf()"
              :loading="isLoading"
              :disabled="isLoading"
              >Essayer
              <template v-slot:isLoading>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn
              type="button"
              class="rn-btn"
              style="background-color: white !important; border-color: #606770"
              variant="outline-primary"
              :ref="'button' + pricing.slug"
              disabled
              v-else-if="pricing.slug == 'FREE'"
              >Essayer
            </v-btn>
            <v-btn
              type="button"
              class="btn-custom"
              variant="outline-primary"
              size="lg"
              :ref="'button' + pricing.slug"
              @click="manageStripe(pricing.slug)"
              v-else-if="pricing.slug != 'FREE' && subscription == pricing.slug"
              :loading="isLoading"
              :disabled="isLoading"
              style="background-color: white !important; color: red !important"
            >
              <template v-slot:isLoading>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
              Gérer
            </v-btn>

            <v-btn
              type="button"
              class="btn-custom"
              variant="outline-primary"
              style="background-color: white !important; color: red !important"
              :ref="'button' + pricing.slug"
              v-else-if="
                pricing.slug != 'FREE' &&
                  (subscription == 'FREE' || subscription == null)
              "
              @click="checkout(pricing.slug)"
              :loading="isLoading"
              :disabled="isLoading"
            >
              Acheter
              <template v-slot:isLoading>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn
              type="button"
              class="rn-btn"
              style="background-color: white !important; border-color: #606770"
              variant="outline-primary"
              :ref="'button' + pricing.slug"
              v-else-if="
                pricing.slug != 'FREE' &&
                  subscription != 'FREE' &&
                  subscription != pricing.slug &&
                  subscription != undefined
              "
              disabled
            >
              Acheter
              <template v-slot:isLoading>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <div class="pricing-body mt-5 mb-0" v-if="pricing.id == 3">
              <ul class="list-style--1">
                <li style="color: white">
                  * Engagement de 12 mois. Paiement en 1 fois
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { publishableKeys } from "@/stripe.js";
import API from "@aws-amplify/api";
import { REFRESH_AUTH } from "@/core/services/store/auth.module";

export default {
  data() {
    return {
      publishableKey: "",
      subscription: this.$store.state.auth.user.attributes[
        "custom:subscription"
      ],
      icon: "check",
      isLoading: false,
      pricingContent: [
        {
          id: 1,
          slug: "FREE",
          title: " Pack Découverte",
          price: 0,
          subtitle: "tester le service gratuitement",
          active: false,
          color: "black",
          listItem: [
            {
              list: "limité à un contact à prévenir"
            },
            {
              list: "2 alertes par mois"
            }
          ]
        },
        {
          id: 3,
          slug: "PLAN2",
          title: "Pack Premium",
          price: 2.99,
          subtitle: "par mois *",
          active: true,
          color: "white",
          listItem: [
            {
              list: "Identique au Pack assistance"
            },
            {
              list: "Une enceinte offerte la première année"
            },
            {
              list: "économisé plus de 30% *"
            }
          ]
        },
        {
          id: 2,
          slug: "PLAN1",
          title: "Pack Assistance",
          price: 5,
          subtitle: "par mois",
          active: false,
          color: "white",
          listItem: [
            {
              list: "limité à 5 contacts à prévenir"
            },
            {
              list: "50 alertes par mois"
            },
            {
              list: "Tout inclus."
            },
            {
              list: "sans engagement."
            },
            {
              list: "Résiliation gratuite."
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.publishableKey = publishableKeys[process.env.NODE_ENV];
    if (
      localStorage.userPlan !== undefined &&
      localStorage.userPlan !== "undefined"
    ) {
      const userPlan = localStorage.userPlan;
      localStorage.removeItem("userPlan");
      switch (userPlan) {
        case ("FREE", undefined):
          this.tryAppPerf();
          break;
        case "PLAN1":
        case "PLAN2":
          this.checkout(userPlan);
          break;
      }
    }
  },
  methods: {
    async manageStripe(plan) {
      this.isLoading = true;
      await this.$gtm.trackEvent({
        category: "User",
        action: "stripe",
        label: "manage",
        value: plan
      });
      const customerPortal = await API.post(
        "teleassistanceseniorstripeapi",
        "/customer-portal",
        {
          body: {
            plan,
            redirectUri: `${window.location.origin}`
          }
        }
      );
      this.isLoading = false;
      document.location.href = customerPortal.url;
    },
    async tryAppPerf() {
      if (this.subscription === "FREE") {
        return this.$emit("updateplan");
      }
      if (this.subscription !== undefined) {
        Swal.fire({
          title: "Erreur",
          text: "Vous devez d'abord annuler votre abonnement",
          icon: "info",
          heightAuto: false
        });
        return;
      }
      this.isLoading = true;

      try {
        await API.post("teleassistanceseniorstripeapi", "/free-plan", {
          body: {}
        });
        await this.$store.dispatch(REFRESH_AUTH);
      } catch (err) {
        Swal.fire({
          title: "",
          text: err.message,
          icon: "error",
          heightAuto: false
        });
      }
      this.isLoading = false;
      this.$emit("updateplan");
    },
    async checkout(plan) {
      if (this.subscription === "PLAN1" || this.subscription === "PLAN2") {
        return this.manageStripe(plan);
      }
      this.isLoading = true;
      await this.$gtm.trackEvent({
        category: "User",
        action: "stripe",
        label: "checkout",
        value: plan
      });
      const sessionData = await API.post(
        "teleassistanceseniorstripeapi",
        "/checkout",
        {
          body: {
            planType: plan,
            redirectUri: `${window.location.origin}`
          }
        }
      );
      this.isLoading = false;
      const stripe = window.Stripe(this.publishableKey);
      stripe.redirectToCheckout({
        sessionId: sessionData.sessionId
      });
    }
  }
};
</script>
