<template>
  <v-row class="mt--30" justify="center"
    >>
    <!-- Start Single Counterup  -->
    <v-col
      lg="3"
      md="3"
      sm="6"
      cols="12"
      class="im_single_counterup"
      v-for="(counter, i) in counterUpContent"
      :key="i"
    >
      <div class="im_counterup">
        <div class="inner">
          <div class="icon">
            <v-icon v-html="counter.icon" style="font-size: 60px"> </v-icon>
          </div>
          <h5 class="counter count">
            <VisibilitySensor @change="onChange">
              <countTo
                :endVal="status ? counter.endVal : 0"
                :autoplay="countUp"
                :duration="3000"
              ></countTo>
            </VisibilitySensor>
          </h5>
          <p class="description">
            {{ counter.desc }}
          </p>
        </div>
      </div>
    </v-col>
    <!-- Start Single Counterup  -->
  </v-row>
</template>

<script>
import VisibilitySensor from "vue-visibility-sensor";
import countTo from "vue-count-to";
export default {
  components: {
    countTo,
    VisibilitySensor
  },
  data() {
    return {
      countUp: true,
      status: false,
      counterUpContent: [
        {
          icon: "mdi-account",
          endVal: 501,
          desc: `clients`
        },
        {
          icon: "mdi-emoticon-happy-outline",
          endVal: 1575,
          desc: `contacts`
        },
        {
          icon: "mdi-message-processing-outline",
          endVal: 3548,
          desc: `messages envoyés`
        }
      ]
    };
  },
  methods: {
    onChange(isVisible) {
      if (isVisible) {
        this.status = true;
      }
    }
  }
};
</script>
