<template>
  <v-row justify="center">
    <v-col
      lg="4"
      md="4"
      sm="4"
      cols="12"
      class="mt--60"
      v-for="pricing in pricingContent"
      :key="pricing.id"
    >
      <div class="rn-pricing" :class="{ active: pricing.active }">
        <div class="pricing-table-inner">
          <div class="pricing-header">
            <h4 class="heading-title">{{ pricing.title }}</h4>
            <div class="pricing">
              <span class="price">{{ pricing.price }}€</span>
              <span class="subtitle">{{ pricing.subtitle }}</span>
            </div>
          </div>

          <div class="pricing-body">
            <ul class="list-style--1">
              <li v-for="(singleList, i) in pricing.listItem" :key="i">
                <v-icon light dense v-bind:color="pricing.color"
                  >mdi-check</v-icon
                >{{ singleList.list }}
              </li>
            </ul>
          </div>

          <div class="pricing-footer">
            <a class="rn-btn" href="/user/subscription">Souscrire</a>
          </div>
          <div class="pricing-body mt-5 mb-0" v-if="pricing.id == 3">
            <ul class="list-style--1">
              <li style="color: white">* Engagement de 12 mois.</li>
            </ul>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      icon: "check",
      pricingContent: [
        {
          id: 1,
          slug: "FREE",
          title: " Pack Découverte",
          price: 0,
          subtitle: "testez le service gratuitement",
          active: false,
          color: "black",
          listItem: [
            {
              list: "limité à un contact à prévenir"
            },
            {
              list: "2 alertes par mois"
            }
          ]
        },
        {
          id: 3,
          slug: "PLAN2",
          title: "Pack Premium",
          price: 2.99,
          subtitle: "par mois *",
          active: true,
          color: "white",
          listItem: [
            {
              list: "Identique au Pack assistance"
            },
            {
              list: "Une enceinte offerte la première année"
            },
            {
              list: "économisé plus de 30% *"
            }
          ]
        },
        {
          id: 2,
          slug: "PLAN1",
          title: "Pack Assistance",
          price: 5,
          subtitle: "par mois",
          active: false,
          color: "white",
          listItem: [
            {
              list: "limité à 5 contacts à prévenir"
            },
            {
              list: "50 alertes par mois"
            },
            {
              list: "Tout inclus."
            },
            {
              list: "sans engagement."
            },
            {
              list: "Résiliation gratuite."
            }
          ]
        }
      ]
    };
  }
};
</script>
<style></style>
