<template>
  <v-row class="row--20 align-center">
    <v-col md="6" sm="12" cols="12">
      <div class="thumbnail">
        <img
          class="w-100"
          src="../../assets/images/about/woman.jpg"
          alt="About Images"
        />
      </div>
    </v-col>
    <v-col md="6" sm="12" cols="12">
      <div class="about-inner inner">
        <div class="section-title">
          <span class="subtitle">Pourquoi souscrire?</span>
          <h2 class="heading-title">Avantages</h2>
          <p class="description">
            Vous souhaitez avoir une présence chez vous, être rassuré et plus
            serein ?
            <br />
            Vous disposez d'un assistant vocal Alexa / Google Assistant ?
            <br />
            Profitez de notre systéme d'alerting pour conserver un lien avec vos
            proches dans toutes les situations.
          </p>
        </div>
        <v-row>
          <v-col md="6" sm="6" cols="12">
            <div class="about-us-list">
              <h5 class="title">La simplicité.</h5>
              <p>Demandez de l'assistance juste par la voix.</p>
            </div>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div class="about-us-list">
              <h5 class="title">Tout inclus.</h5>
              <p>Aucun matériel, aucun frais supplémentaire.</p>
            </div>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div class="about-us-list">
              <h5 class="title">Abonnement sans engagement.</h5>
              <p>Vous pouvez y mettre fin quand vous le souhaitez.</p>
            </div>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div class="about-us-list">
              <h5 class="title">Résiliation gratuite</h5>
              <p>Sans aucun frais en cas de résiliation.</p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
