<template>
  <v-row align="start" class="row--35">
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <div class="text-left section-title mb--50">
        <span class="subtitle">Plus d'info?</span>
        <h2 class="heading-title">Contactez nous.</h2>
        <div>
          <ul class="choice-form">
            <li>Vous voulez en savoir plus sur "mes amis de confiance" ?</li>
            <li>Vous rencontrez un problème pour utiliser notre service ?</li>
            <li>
              Vous voulez intégrer "mes amis de confiance" en marque blanche
              dans votre service de télé-assistance? <br />
            </li>
          </ul>
          Envoyez-nous un petit message, nous ne manquerons pas de vous répondre
          ...
        </div>
      </div>
      <div>
        <form id="contact_form" novalidate="novalidate" class="col-12">
          <v-text-field
            type="text"
            id="name"
            name="name"
            v-model="formData.name"
            label="Votre nom"
            :error-messages="nameErrors"
            @input="$v.formData.name.$touch()"
            @blur="$v.formData.name.$touch()"
          ></v-text-field>
          <v-text-field
            type="text"
            id="email"
            name="email"
            v-model="formData.email"
            label="Votre email"
            :error-messages="emailErrors"
            @input="$v.formData.email.$touch()"
            @blur="$v.formData.email.$touch()"
          ></v-text-field>
          <v-textarea
            id="message"
            name="message"
            v-model="formData.message"
            label="Votre message"
            :error-messages="messageErrors"
            @input="$v.formData.message.$touch()"
            @blur="$v.formData.message.$touch()"
          ></v-textarea>

          <v-btn
            type="button"
            class="btn-custom"
            variant="outline-primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="submit"
          >
            Contactez-nous
            <template v-slot:isLoading>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </form>
      </div>
    </v-col>

    <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
      <div class="thumbnail mb_md--40 mb_sm--40">
        <slot name="contact-img"></slot>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import API from "@aws-amplify/api";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, email, helpers } from "vuelidate/lib/validators";

const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ú \- s]+$/i);

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      formData: {
        name: "",
        email: "",
        message: ""
      }
    };
  },
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required, alpha },
      message: { required },
      email: { required, email }
    }
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.formData.name.$dirty) return errors;
      !this.$v.formData.name.required && errors.push("Le nom est obligatoire");
      !this.$v.formData.name.alpha &&
        errors.push("Le nom ne doit contenir que des lettres");

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.formData.email.$dirty) return errors;
      !this.$v.formData.email.email &&
        errors.push("l'adresse mail n'est pas valide");
      !this.$v.formData.email.required &&
        errors.push("l'adresse mail est obligatoire");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.formData.message.$dirty) return errors;
      !this.$v.formData.message.required &&
        errors.push("Un message est obligatoire");
      return errors;
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      try {
        await API.post("teleassistancesenior0cfffb9acontactapi", "/add", {
          body: this.formData
        });
        Swal.fire({
          title: "",
          text: "message envoyé",
          icon: "info",
          heightAuto: false
        });
        this.$v.$reset();
      } catch (err) {
        Swal.fire({
          title: "",
          text: err.message,
          icon: "error",
          heightAuto: false
        });
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
.form-wrapper label input,
.form-wrapper label textarea {
  margin-bottom: 0;
}

.form-wrapper label {
  margin-bottom: 20px;
}

.choice-form {
  margin-left: 40px;
}

.btn:focus {
  outline: none;
  box-shadow: none;
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.v-autocomplete__content .v-list-item--link {
  pointer-events: none;
  // cursor: none;
  //user-select: none;
}

.fv-help-block {
  color: red;
  font-size: 14px;
}

.btn-default {
  padding: 0.375rem 0.75rem !important;
  margin-right: 10px;
}

.v-text-field__slot input {
  border: none;
}
</style>
