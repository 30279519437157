<template>
  <div>
    <VueSlickCarousel
      v-bind="settings"
      class="slider-activation rn-slick-dot dot-light"
    >
      <div
        class="slide slide-style-2  slider-box-content without-overlay d-flex align-center bg_image"
        data-black-overlay="2"
        v-for="(slider, i) in sliderContent"
        :key="i"
        :style="{ backgroundImage: 'url(' + slider.src + ')' }"
      >
        <v-container>
          <div class="text-left inner">
            <h1 class="heading-title">{{ slider.title }}</h1>
            <p class="description">
              {{ slider.desc }}
            </p>
            <div class="slide-btn">
              <router-link
                class="btn-default"
                to="/user/login"
                v-if="!isAuthenticated"
                >Je me connecte</router-link
              >
              <router-link
                class="btn-default"
                to="/user/dashboard"
                v-if="isAuthenticated"
                >Mon compte</router-link
              >
            </div>
          </div>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { mapGetters } from "vuex";

export default {
  components: { VueSlickCarousel },
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  data() {
    return {
      sliderContent: [
        {
          src: require("../../assets/images/bg/senior-woman-with-walker-at-home-Q8CNHTT-min.jpg"),
          title: "Mes amis de confiance",
          desc: `le premier service d'alerting fondé sur l’entraide disponible sur les assistants vocaux Google Assistant et Alexa.`
        },
        {
          src: require("../../assets/images/bg/senior-woman-reading-book-at-home-4KJ9EQB-min.jpg"),
          title: "Mes amis de confiance",
          desc: `Mon réseau d’alerte personnel fondé sur l’entraide`
        }
      ],
      settings: {
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        margin: 20
      }
    };
  }
};
</script>

<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
.slide.slide-style-2.without-overlay .inner p.description {
  opacity: 1;
  font-weight: 600;
}
</style>
