import Vue from "vue";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const REFRESH_AUTH = "refreshAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const UPDATE_EMAIL = "updateEmail";
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SHOWMENU = "showMenu";

const state = {
  errors: null,
  user: {},
  showMenu: true,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  showMenu(state) {
    return state.showMenu;
  }
};

const actions = {
  [SHOWMENU](context, value) {
    state.showMenu = value;
  },
  async [LOGIN](context, credentials) {
    return Vue.prototype.$Amplify.Auth.signIn(
      credentials.email,
      credentials.password
    ).then(data => {
      context.commit(SET_AUTH, data);
    });
  },
  [LOGOUT](context) {
    Vue.$cookies.remove("user_id");
    return Vue.prototype.$Amplify.Auth.signOut().then(() => {
      context.commit(PURGE_AUTH);
    });
  },
  [REGISTER](context, credentials) {
    return Vue.prototype.$Amplify.Auth.confirmSignUp(
      credentials.email,
      credentials.code
    ).then(data => {
      if (data && data.signInUserSession) {
        context.commit(SET_AUTH, data);
      } else {
        context.commit(SET_ERROR, "error auth");
      }
    });
  },
  [UPDATE_EMAIL](context, credentials) {
    return Vue.prototype.$Amplify.Auth.verifyCurrentUserAttributeSubmit(
      "email",
      credentials.code
    ).then(data => {
      if (data === "SUCCESS") {
        return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser({
          bypassCache: true
        })
          .then(data => {
            if (data && data.signInUserSession) {
              context.commit(SET_AUTH, data);
            }
          })
          .catch(err => {
            context.commit(SET_ERROR, err);
            context.commit(PURGE_AUTH);
          });
      } else {
        context.commit(SET_ERROR, "error auth");
      }
    });
  },
  [VERIFY_AUTH](context) {
    return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
      .then(data => {
        if (data && data.signInUserSession) {
          context.commit(SET_AUTH, data);
        }
      })
      .catch(err => {
        context.commit(SET_ERROR, err);
        context.commit(PURGE_AUTH);
      });
  },
  [REFRESH_AUTH](context) {
    return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser({
      bypassCache: true
    })
      .then(data => {
        if (data && data.signInUserSession) {
          context.commit(SET_AUTH, data);
        }
      })
      .catch(err => {
        context.commit(SET_ERROR, err);
        context.commit(PURGE_AUTH);
      });
  }
  // [UPDATE_PASSWORD](context, payload) {}
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SHOWMENU](state) {
    state.showMenu = false;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
